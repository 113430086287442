<template>
  <div class="c-app page-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="12" class="content">
          <CRow class="justify-content-center">
            <CCol md="12" class="form-container d-flex flex-column justify-content-center align-items-center">
              <div class="content-wrapper">
                <div class="form-auth d-flex flex-column justify-content-center align-items-start">
                  <h3>{{ $t('common.field.reset.password') }}!</h3>
                  <CCard>
                    <CForm novalidate @submit.prevent="">
                      <CCardBody>
                        <CInput
                          type="password"
                          required
                          :addInputClasses="{
                            'is-valid': isPasswordValid(password),
                            'is-invalid': isFormSubmitted && !isPasswordValid(password),
                          }"
                          v-model="password"
                          autocomplete="current-password"
                          :label="$t('common.labels.newPassword')"
                          :placeholder="$t('common.placeholders.password')"
                          :invalid-feedback="$t(password ? 'common.labels.passwordPolicy' : 'common.labels.emptyField')"
                        />
                        <CInput
                          type="password"
                          required
                          :addInputClasses="{
                            'is-valid': password && password === confirmPassword,
                            'is-invalid': isFormSubmitted && password && password !== confirmPassword,
                          }"
                          v-model="confirmPassword"
                          autocomplete="current-password"
                          :label="$t('common.labels.confirmNewPassword')"
                          :placeholder="$t('common.placeholders.password')"
                          :invalid-feedback="$t('common.labels.passwordsDoNotMatch')"
                        />
                      </CCardBody>
                    </CForm>
                  </CCard>
                </div>
              </div>
              <div class="btn-wrapper">
                <CButton class="btn-confirm" @click="resetPassword">
                  <ClipLoader :loading="isRequestInProgress" color="white" size="25px"></ClipLoader>
                  {{ isRequestInProgress ? '' : $t('common.buttons.save') }}</CButton
                >
                <CButton color="link" class="mt-3" @click="() => goToRoute('/welcome')">{{ $t('common.buttons.return') }}</CButton>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import config from '@/config';

export default {
  name: 'ResetPassword',
  mixins: [apiErrorsMixin],
  components: {},
  data() {
    return {
      isFormSubmitted: false,
      isRequestInProgress: false,
      password: null,
      confirmPassword: null,
      config,
    };
  },
  methods: {
    isPasswordValid(value) {
      return /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(value);
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    resetPassword() {
      this.isFormSubmitted = true;

      if (!this.isPasswordValid(this.password) || this.password !== this.confirmPassword) {
        return;
      }

      this.isRequestInProgress = true;

      this.$http
        .post(`/api/user/reset/${this.$route.params.token}`, {
          password: this.password,
        })
        .then(() => {
          this.$notifications.clear();
          this.$notify({
            title: this.$t('common.messages.successfullyReset'),
            type: 'success',
          });
        })
        .catch(this.apiErrorCallback)
        .then(() => {
          this.isRequestInProgress = false;
          this.password = '';
          this.confirmPassword = '';
          this.isFormSubmitted = false;

          this.$router.push('/login');
        });
    },
  },
};
</script>
