var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app page-login flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"content",attrs:{"md":"12"}},[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"form-container d-flex flex-column justify-content-center align-items-center",attrs:{"md":"12"}},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"form-auth d-flex flex-column justify-content-center align-items-start"},[_c('h3',[_vm._v(_vm._s(_vm.$t('common.field.reset.password'))+"!")]),_vm._v(" "),_c('CCard',[_c('CForm',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('CCardBody',[_c('CInput',{attrs:{"type":"password","required":"","addInputClasses":{
                          'is-valid': _vm.isPasswordValid(_vm.password),
                          'is-invalid': _vm.isFormSubmitted && !_vm.isPasswordValid(_vm.password),
                        },"autocomplete":"current-password","label":_vm.$t('common.labels.newPassword'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t(_vm.password ? 'common.labels.passwordPolicy' : 'common.labels.emptyField')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('CInput',{attrs:{"type":"password","required":"","addInputClasses":{
                          'is-valid': _vm.password && _vm.password === _vm.confirmPassword,
                          'is-invalid': _vm.isFormSubmitted && _vm.password && _vm.password !== _vm.confirmPassword,
                        },"autocomplete":"current-password","label":_vm.$t('common.labels.confirmNewPassword'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t('common.labels.passwordsDoNotMatch')},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"btn-wrapper"},[_c('CButton',{staticClass:"btn-confirm",on:{"click":_vm.resetPassword}},[_c('ClipLoader',{attrs:{"loading":_vm.isRequestInProgress,"color":"white","size":"25px"}}),_vm._v("\n                "+_vm._s(_vm.isRequestInProgress ? '' : _vm.$t('common.buttons.save')))],1),_vm._v(" "),_c('CButton',{staticClass:"mt-3",attrs:{"color":"link"},on:{"click":() => _vm.goToRoute('/welcome')}},[_vm._v(_vm._s(_vm.$t('common.buttons.return')))])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }