<template>
  <div class="parent-px-0 parent-pt-0 page-medias-list">
    <div class="back-button px-4 px-xl-5">
      <CIcon name="arrowLeft" height="24" width="24" />
      <router-link to="/app/medias">Retour</router-link>
    </div>
    <h3 class="px-5 pt-3">{{ title }}</h3>
    <div class="px-5">
      <mediaWidget
        col="12"
        md="6"
        xl="4"
        :type="type"
        :sort="sort"
        :programId="programId"
        :favorites="favorites"
      />
    </div>
  </div>
</template>

<script>
import mediaWidget from '@/components/widgets/Media.vue';

export default {
  name: 'MediasList',
  components: {
    mediaWidget,
  },
  data() {
    return {
      title: this.$route.params.title,
      type: this.$route.params.type,
      sort: this.$route.params.sort || 'lastModifiedOn',
      programId: this.$route.params.programId || null,
      favorites: this.$route.params.favorites || false,
    };
  },
};
</script>
