<template>
  <div
    class="page-dashboard"
    :class="{
      'tour-step-1': this.currentTourStep === 1,
      'tour-step-2': this.currentTourStep === 2,
      'tour-step-3': this.currentTourStep === 3,
    }"
  >
    <div class="row tour-highlight-area">
      <CAlert v-if="false" color="primary col-12 order-0">
        <router-link to="/app/program-day/1">Voir la page Atelier</router-link>
      </CAlert>
      <div class="col-md-8 p-0 pr-md-1 dashboard-column-left">
        <CCard class="p-0 card-welcome">
          <CCardBody>
            <div class="row px-3">
              <div class="mb-3 text-center float-left">
                <img class="img-fluid" :src="womanImg" alt="" />
              </div>
              <div class="d-md-none align-self-center">
                <h5 class="font-weight-bolder">Bonjour {{ firstName }} !</h5>
              </div>
              <div class="col-12 col-md-6 p-0">
                <h5 class="d-sm-down-none font-weight-bolder">Bonjour {{ firstName }} !</h5>
                <p class="pt-3 f-16">
                  Comment vous sentez-vous aujourd’hui ?
                  <br />
                  <br />Evaluez et suivez l'évolution de votre qualité de vie en 2 minutes !
                </p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-warning w-100 font-weight-bold"
              data-toggle="modal"
              data-target="#reportFormModal"
              data-track-category="self-evaluation"
              data-track-action="self-evaluation-started"
              data-track-name="source"
              data-track-value="dashboard"
            >
              Je remplis mes indicateurs
            </button>
          </CCardBody>
        </CCard>
        <div class="d-md-none">
          <dashboardWidget />
        </div>
        <achievementsWidget />
        <div class="d-md-none">
          <programsProgressionWidget />
          <reportRadarWidget />
        </div>
        <CCard class="p-0">
          <CCardHeader>
            <span class="f-18">{{ $tc('common.labels.video', 2) }} les plus vues</span>
            <a @click="() => goToRoute('/app/medias')" class="pr-1 font-weight-light float-right">
              Voir + <span class="align-middle text-primary">→</span>
            </a>
          </CCardHeader>
          <CCardBody>
            <mediaWidget type="video" limit="5" col="8" xl="4" sort="viewsCount" />
          </CCardBody>
        </CCard>
        <CCard class="p-0">
          <CCardHeader>
            <span class="f-18">{{ $tc('common.labels.podcast', 2) }} les plus vus</span>
            <a @click="() => goToRoute('/app/medias')" class="pr-1 font-weight-light float-right">
              Voir + <span class="align-middle text-primary">→</span>
            </a>
          </CCardHeader>
          <CCardBody>
            <mediaWidget type="podcast" limit="5" col="8" xl="4" sort="viewsCount" />
          </CCardBody>
        </CCard>
      </div>
      <div class="col-md-4 p-0 pl-md-1 d-sm-down-none dashboard-column-right">
        <dashboardWidget />
        <programsProgressionWidget />
        <reportRadarWidget />
      </div>
    </div>

    <div v-if="isTutorialOverlayDisplayed" class="page-dashboard-overlay" />

    <v-tour name="firstVisitTour" :steps="steps" :callbacks="tourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template>
              <div slot="actions" class="v-step__actions">
                <button v-if="tour.currentStep !== 3" @click="() => finishTour(tour, true)" class="btn btn-link">Passer la visite</button>
                <button @click="() => finishTour(tour)" class="btn btn-primary">J'ai compris</button>
              </div>
            </template>
          </v-step>
        </transition>
      </template></v-tour
    >
    <div
      class="modal fade"
      id="reportFormModal"
      ref="reportFormModalRef"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reportFormModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content h-100 v-100 p-0">
          <div class="modal-body p-0">
            <reportFormWidget @onDataSaved="() => $router.push('/app/reports')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import programsProgressionWidget from '@/components/widgets/ProgramsProgression.vue';
import dashboardWidget from '@/components/widgets/Dashboard.vue';
import mediaWidget from '@/components/widgets/Media.vue';
import reportFormWidget from '@/components/widgets/ReportForm.vue';
import reportRadarWidget from '@/components/widgets/ReportRadar.vue';
import achievementsWidget from '@/components/widgets/Achievements.vue';
import womanImg from '../../assets/images/woman.png';

export default {
  name: 'Dashboard',
  components: {
    programsProgressionWidget,
    dashboardWidget,
    reportRadarWidget,
    mediaWidget,
    reportFormWidget,
    achievementsWidget,
  },
  data() {
    return {
      womanImg,
      dashboardStats: null,
      isTutorialOverlayDisplayed: false,
      tourCallbacks: {
        onStart: this.onTourStarted,
        onFinish: this.onTourFinished,
        onSkip: this.onTourFinished,
        onNextStep: this.onNextTourStep,
      },
      currentTourStep: -1,
      steps: [
        {
          target: this.isMobile() ? '.nav-dock' : '.c-sidebar',
          header: {
            title: '1/4 Visite Guidée',
          },
          content: 'Votre menu vous permet d’accéder simplement aux différentes sections de la plateforme.',
          params: {
            placement: this.isMobile() ? 'top' : 'right',
          },
        },
        {
          target: '.card-objectives-info',
          header: {
            title: '2/4 Visite Guidée',
          },
          params: {
            placement: 'top',
          },
          content: 'Scroller vers le bas pour découvrir plus de nouveaux contenus.',
        },
        {
          target: '.app-header',
          header: {
            title: '3/4 Visite Guidée',
          },
          content: 'Ici vous retrouverez l’ensemble de vos notifications et rappels.',
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '.card-objectives-info',
          header: {
            title: '4/4 Visite Guidée',
          },
          content:
            // eslint-disable-next-line max-len
            'Vous retrouverez ici l’ensemble des informations vous concernant et votre état d’avancement sur la plateforme.',
          params: this.isMobile() ? {} : { placement: 'left' },
        },
      ],
    };
  },
  computed: {
    firstName() {
      return this.$store.state.user && this.$store.state.user.firstName;
    },
    isTutorialCompleted() {
      return this.$store.state.user && this.$store.state.user.dashboardTutorialCompleted;
    },
  },
  methods: {
    isMobile() {
      return window.outerWidth < 992;
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    highlightTourArea(stepIndex) {
      for (let i = 0; i < this.steps.length; i++) {
        if (i !== stepIndex) {
          $(`.dashboard-tour-show-step-${i}`).css('z-index', '1000');
        }
      }

      if (this.steps[stepIndex]) {
        $(`.dashboard-tour-show-step-${stepIndex}`).css('z-index', '100000');
      }
    },
    onTourStarted() {
      this.isTutorialOverlayDisplayed = true;
      this.currentTourStep = 0;

      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;

      this.highlightTourArea(0);
      this.$forceUpdate();
    },
    onTourFinished() {
      this.isTutorialOverlayDisplayed = false;
      this.currentTourStep = -1;

      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

      this.highlightTourArea('all');
      this.$forceUpdate();
    },
    onNextTourStep(currentStep) {
      this.highlightTourArea(currentStep + 1);
      this.currentTourStep = currentStep + 1;
    },
    async finishTour(tour, force) {
      if (!tour) {
        return;
      }

      if (tour.currentStep === 3 || force) {
        tour.finish();

        const { user } = this.$store.state;
        if (user) {
          await this.$http.put(`/api/user/${user.id}`, {
            dashboardTutorialCompleted: true,
          });
          this.$store.dispatch('refreshUser');
        }
      } else {
        tour.nextStep();
      }
    },
  },
  mounted() {
    if (!this.isTutorialCompleted && !this.isMobile()) {
      this.$tours.firstVisitTour.start();
    }
  },
};
</script>
