<template>
  <div class="program-selection-page d-flex flex-column align-items-center justify-content-between">
    <div class="title-row">
      <div class="btn-back" @click="goToRoute('/app/programs')">
        <img :src="arrowLeftIcon" class="arrow-icon" />
      </div>
      <h2 class="program-selection-title ml-3">Choisir un programme</h2>
    </div>
    <div v-if="selectedProfile && !selectedProgram" class="profile-info-wrapper mt-3">
      <div class="d-flex align-items-center mr-3">
        <img :src="profileIcon" class="profile-icon mr-2" />
        <div class="profile-label ml-2">Mon profil</div>
      </div>
      <div class="profile-name">{{ selectedProfile.title }}</div>
    </div>
    <div v-if="!selectedProgram" class="program-list-wrapper mt-4">
      <div v-for="(program, index) in programs" :key="index" class="mb-3 mr-3 ml-3" @click="selectedProgram = program">
        <div class="program-card">
          <img :src="program.image || defaultProgramImage" class="program-image" />
          <div class="program-title mt-2">
            {{ program.title }}
          </div>
          <div class="program-duration-row mt-2">
            <img :src="calendarIcon" class="calendar-icon" />
            <div class="program-duration ml-2">{{ convertDurationToHoursAndMinutes(program.estimatedDuration) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedProgram" class="program-details-wrapper mt-4">
      <div class="program-duration-row">
        <img :src="coloredCalendarIcon" class="calendar-icon" />
        <div class="program-duration ml-2">{{ convertDurationToHoursAndMinutes(selectedProgram.estimatedDuration) }}</div>
      </div>
      <div class="program-title mt-4">
        {{ selectedProgram.title }}
      </div>
      <div class="program-description mt-3">
        {{ selectedProgram.description }}
      </div>
      <div class="btn-wrapper mt-5">
        <button type="button" class="btn btn-info btn-fill" @click="changeProgram">Choisir ce programme</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import utils from '../../utils';
import arrowLeftIcon from '../../assets/icons/arrow-left.svg';
import profileIcon from '../../assets/icons/profile-circle.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import coloredCalendarIcon from '../../assets/icons/calendar-colored.svg';
import defaultProgramImage from '../../assets/images/default-program-image.png';

export default {
  name: 'ProgramSelection',
  data() {
    return {
      programs: [],
      profilePrograms: [],
      selectedProgram: null,
      arrowLeftIcon,
      profileIcon,
      calendarIcon,
      coloredCalendarIcon,
      defaultProgramImage,
    };
  },
  async mounted() {
    await this.getPrograms();
  },
  computed: {
    selectedProfile() {
      return this.$store.state.selectedProfile;
    },
    hasUserActiveSubscription() {
      const { user } = this.$store.state;

      if (!user) {
        return false;
      }

      return (
        this.$store.state.user
        && this.$store.state.user.Subscriptions
        && this.$store.state.user.Subscriptions[0]
        && moment(this.$store.state.user.Subscriptions[0].activeUntil).isAfter()
      );
    },
  },
  watch: {
    '$store.state.selectedProfile': function onSelectedProfileChange() {
      this.getPrograms();
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    async getPrograms() {
      if (!this.selectedProfile) {
        this.programs = [];
        return;
      }

      const { data } = await this.$http.get(`/api/profile_program?filters[profileId]=${this.selectedProfile.id}`);
      if (data && data.body) {
        const profilePrograms = data.body;
        this.profilePrograms = profilePrograms;
        this.programs = profilePrograms.map((item) => item.Program);
      } else {
        this.profilePrograms = [];
      }
    },
    async changeProgram() {
      if (!this.selectedProgram) {
        return;
      }

      const profileProgram = this.profilePrograms.find((item) => item.programId === this.selectedProgram.id);
      const isProgramTrial = profileProgram ? profileProgram.isTrial : false;

      if (!this.hasUserActiveSubscription && !isProgramTrial) {
        return this.$router.push(`/app/subscription?pendingProgramId=${this.selectedProgram.id}`);
      }

      const { user } = this.$store.state;
      if (user) {
        await this.$http.put(`/api/user/${user.id}`, {
          programId: this.selectedProgram.id,
        });
        this.$store.dispatch('refreshUser');
        this.goToRoute('/app/programs');
      }
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
  },
};
</script>

<style scoped></style>
