<template>
  <div class="c-app page-welcome flex-row align-items-center">
    <img :src="publicBgImage" class="bg-image" />
    <div class="medical-image-wrapper">
      <img :src="medicalRecordImage" />
    </div>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="12" class="content">
          <CRow>
            <CCol xs="12" md="12" class="form-container d-flex justify-content-center align-items-center">
              <div class="form-auth d-flex flex-column justify-content-center align-items-center">
                <h3>Bienvenue sur Doclivi !</h3>
                <div class="btn-wrapper mt-5">
                  <CButton class="btn-confirm" @click="$router.push('/login')">Se connecter</CButton>
                  <CButton class="btn-register" @click="$router.push('/register')">S’inscrire</CButton>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import publicBgImage from '../../assets/images/public-bg.png';
import medicalRecordImage from '../../assets/images/default-program-image.png';

export default {
  name: 'WelcomePage',
  mixins: [apiErrorsMixin],
  data() {
    return {
      publicBgImage,
      medicalRecordImage,
    };
  },
  mounted() {
    if (this.$store.state.user && this.$store.state.user.id && this.$store.state.user) {
      this.$router.push('/app/dashboard');
    }
  },
};
</script>
