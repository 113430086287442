<template>
  <div class="card card-media pointer" @click="openMediaModal(media)">
    <div class="card-img-container">
      <img v-if="media.thumbnail" class="card-img-top" :src="media.thumbnail" alt="Media image" />
      <span
        v-if="media.Program && media.Program.title"
        class="media-tag"
        :style="{ 'background-color': media.Program.color || '#47776E' }"
        >{{ media.Program.title }}
      </span>
      <MediaFavoriteIcon :mediaId="media.id" />
    </div>
    <div class="card-body p-0 px-4">
      <div class="row justify-content-between f-12 font-weight-normal py-2">
        <span>{{ formatDate(media.createdOn) }}</span>
        <span>{{ media.videoLength }}</span>
      </div>
      <div class="row">
        <p class="media-title overflow-hidden f-16 m-0">
          {{ media.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MediaFavoriteIcon from '@/components/widgets/MediaFavoriteIcon.vue';

export default {
  name: 'mediaCard',
  components: {
    MediaFavoriteIcon,
  },
  props: ['media'],
  data() {
    return {};
  },
  methods: {
    formatDate(dateTime) {
      return moment(String(dateTime)).format('DD/MM/YYYY');
    },
    openMediaModal(media) {
      if (!media) {
        return;
      }
      this.$store.commit('mediaModalContent', media);
      this.$nextTick(() => {
        $('#mediaModal').modal('show');
      });
    },
  },
};
</script>
