<template>
  <div class="profile-page tab-content d-flex flex-column align-items-center justify-content-between">
    <div class="d-flex flex-column align-items-center">
      <span class="profile-page-title">{{ $t('common.labels.thanks') }}</span>
      <h4 class="profile-page-description mb-4">{{ $t('common.labels.profile-welcome-text') }}</h4>
      <h4 v-if="selectedProfile" class="profile-page-description mb-4">
        {{ $t('common.labels.profile-welcome-text-2') }} {{ selectedProfile.title }}
      </h4>
      <h4 class="profile-page-description mb-4">
        {{ $t('common.labels.profile-welcome-text-3') }}
      </h4>
    </div>
    <div class="slide-container">
      <CButton color="success" class="slider-nav d-md-down-none" @click="goToPrevSlide"> <CIcon :src="dropdownIcon" /></CButton>
      <hooper
        @slide="onSlideChanged"
        ref="slider"
        :centerMode="true"
        :mouseDrag="true"
        :infiniteScroll="true"
        :auto-play="false"
        :wheelControl="false"
      >
        <slide v-for="(program, index) in programs" :key="index">
          <div class="card card-primary h-100" text="first">
            <CRow class="h-100">
              <CCol sm="12" lg="7" class="d-flex flex-column justify-content-between align-items-start">
                <div>
                  <h3 class="text-primary mb-3" :style="{ color: program.color ? `${program.color}!important` : '#47776e' }">
                    {{ program.title }}
                  </h3>
                  <div class="program-picture d-lg-none">
                    <img :src="getProgramImage(index)" />
                  </div>
                  <span class="program-description"
                    >{{ $t('common.labels.program-goal') }}: <span>{{ program.description }}</span></span
                  >
                  <div class="mt-4 d-block d-lg-flex justify-content-start align-items-center">
                    <div class="program-info-row">
                      <CIcon :src="clockIcon" class="mr-2" />
                      <b>{{ $t('common.labels.program-time') }}: {{ convertDurationToHoursAndMinutes(program.estimatedDuration) }}</b>
                    </div>
                    <div class="program-info-row">
                      <CIcon :src="checkListIcon" class="mr-2" />
                      <b>{{ $t('common.labels.number-of-days') }}: {{ programDayCount }}</b>
                    </div>
                  </div>
                </div>
                <div class="btn-container d-flex justify-content-start w-100">
                  <CButton color="success" class="mt-5" @click="goToRoute('/app/programs')"> {{ $t('common.buttons.begin') }}</CButton>
                </div>
              </CCol>
              <CCol sm="5" class="program-picture d-md-down-none">
                <img :src="getProgramImage(index)" />
              </CCol>
            </CRow>
          </div>
        </slide>
      </hooper>
      <CButton color="success" class="slider-nav d-md-down-none" @click="goToNextSlide"> <CIcon :src="dropdownIcon" /></CButton>
    </div>
    <div class="btn-container">
      <span>{{ $t('common.labels.or') }}</span>
      <CButton color="link" @click="() => goToRoute('/app/dashboard')">{{ $t('common.buttons.visit-mode') }}</CButton>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import utils from '../../utils';
import dropdownIcon from '../../assets/icons/dropdown.svg';
import clockIcon from '../../assets/icons/Clock.svg';
import checkListIcon from '../../assets/icons/CheckList.svg';
import nutritionImg from '../../assets/images/nutrition.svg';
import formeImg from '../../assets/images/forme.svg';
import equilibreImg from '../../assets/images/equilibre.svg';
import phytotherapieImg from '../../assets/images/phytotherapie.svg';

export default {
  name: 'StudyLogin',
  components: {
    Hooper,
    Slide,
  },

  data() {
    return {
      programs: [],
      programDays: [],
      currentProgram: null,
      dropdownIcon,
      clockIcon,
      checkListIcon,
      programImages: [nutritionImg, formeImg, equilibreImg, phytotherapieImg],
    };
  },
  async mounted() {
    await this.getProfiles();
  },
  computed: {
    profiles() {
      return this.$store.state.profiles || [];
    },
    selectedProfile() {
      return this.$store.state.selectedProfile;
    },
    programDayCount() {
      return this.programDays ? this.programDays.length : 0;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.selectedProfile': function () {
      this.getPrograms();
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    onSlideChanged({ currentSlide }) {
      this.currentProgram = this.programs && this.programs[currentSlide] ? this.programs[currentSlide] : null;
      this.getProgramDays();
    },

    async getProgramDays() {
      if (!this.currentProgram) {
        return;
      }

      const { data } = await this.$http.get(`/api/program_day?filters[programId]=${this.currentProgram.id}&filters[isPublished]=1&sort[order]=DESC`);

      if (data && data.body && data.body.length) {
        this.programDays = data.body.filter((programDay) => programDay && programDay.content && programDay.title);
      }
    },

    goToPrevSlide() {
      if (this.$refs.slider) {
        this.$refs.slider.slidePrev();
      }
    },

    goToNextSlide() {
      if (this.$refs.slider) {
        this.$refs.slider.slideNext();
      }
    },
    getProgramImage(index) {
      if (!this.programs || !this.programs.length) {
        return;
      }

      return this.programImages[index % this.programs.length];
    },

    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },

    getProfiles() {
      this.$store.dispatch('getProfile');
    },

    async getPrograms() {
      if (!this.selectedProfile) {
        this.programs = [];
        this.currentProgram = null;
        this.programDays = [];
        return;
      }

      const { data } = await this.$http.get(`/api/profile_program?filters[profileId]=${this.selectedProfile.id}&sort[order]=ASC`);
      if (data && data.body) {
        const profilePrograms = data.body;

        this.programs = profilePrograms.map((item) => item.Program);
        this.currentProgram = this.programs && this.programs.length ? this.programs[0] : null;
        this.getProgramDays();
      }
    },
  },
};
</script>

<style scoped></style>
