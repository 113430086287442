import Color from 'color';

function convertDurationToHoursAndMinutes(duration) {
  duration = +duration;

  if (duration >= 0) {
    const hours = Math.floor(duration / 60);
    const mins = Math.floor(duration - hours * 60);

    const hourStr = hours > 0 ? `${hours}h` : '';
    let minStr = '0min';

    if (mins > 0) {
      minStr = mins === 1 ? `${mins}min` : `${mins}mins`;
    }

    const time = `${hourStr}${mins < 10 && hours > 0 ? '0' : ''}${minStr}`.trim();

    return time;
  }

  return '0min';
}

function roundFloatValue(value, digits) {
  return value % 1 === 0 ? value : (Math.floor(value * 100) / 100).toFixed(digits);
}

function isProgramDayMediaCompleted(programDayMedia) {
  if (!programDayMedia || !programDayMedia.UsersProgressions || !programDayMedia.UsersProgressions.length) {
    return false;
  }

  return programDayMedia.UsersProgressions[0].status === 'done';
}

function getCardColorByProgramDayMedia(programDayMedia, considerCompleteStatus) {
  const defaultColor = '#23d0b4';

  if (!programDayMedia || !programDayMedia.Media) {
    return defaultColor;
  }

  const isCompleted = isProgramDayMediaCompleted(programDayMedia);

  if (!programDayMedia.Media.Tag || !programDayMedia.Media.Tag.color) {
    return Color(defaultColor).alpha(isCompleted && considerCompleteStatus ? 0.16 : 1).rgb();
  }

  return Color(programDayMedia.Media.Tag.color).alpha(isCompleted && considerCompleteStatus ? 0.16 : 1).rgb();
}

export default {
  convertDurationToHoursAndMinutes, roundFloatValue, isProgramDayMediaCompleted, getCardColorByProgramDayMedia
};
