<template>
  <div
    class="profile-page tab-content d-flex flex-column align-items-center justify-content-between"
  >
    <div class="slide-container">
      <hooper
        ref="slider"
        :centerMode="true"
        :mouseDrag="false"
        :infiniteScroll="false"
        :auto-play="false"
        :wheelControl="false"
      >
        <slide>
          <div class="card card-primary h-100">
            <CRow class="h-100">
              <CCol
                sm="12"
                lg="12"
                class="d-flex flex-column justify-content-between align-items-start"
              >
                <div v-if="study" class="w-100">
                  <h3 class="text-primary mb-3" :style="{ color: '#47776e' }">
                    {{ study.title }}
                  </h3>
                  <div class="program-description" v-html="study.description"></div>
                </div>
                <div class="btn-container d-flex justify-content-start w-100">
                  <CButton
                    variant="ghost"
                    color="success"
                    class="signin mt-5 mr-3"
                    @click="goToLoginPage()"
                  >
                    {{ $t('common.buttons.login') }}</CButton
                  >
                  <CButton
                    variant="ghost"
                    color="success"
                    class="signup mt-5"
                    @click="goToRegisterPage()"
                  >
                    {{ $t('common.buttons.register') }}</CButton
                  >
                </div>
              </CCol>
              <!--
              <CCol sm="5" class="program-picture d-md-down-none">
                <img :src="nutritionImg" />
              </CCol>
              -->
            </CRow>
          </div>
        </slide>
      </hooper>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';

export default {
  name: 'StudyLogin',
  components: {
    Hooper,
    Slide,
  },
  data() {
    return {
      study: null,
    };
  },
  async mounted() {
    await this.getStudy();
  },
  methods: {
    async getStudy() {
      const { studyCode, profileCode } = this.$route.params;
      const { data } = await this.$http.get(`/api/study?filters[code]=${studyCode}`);

      if (data && data.body && data.body.length) {
        this.study = data.body && data.body[0];
      }

      if (!this.study) {
        this.$router.push(`/${profileCode}/login`);
      }
    },

    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },

    goToLoginPage() {
      const { studyCode, profileCode } = this.$route.params;
      this.$store.commit('studyCode', studyCode);
      this.$router.push(`/${profileCode}/login`);
    },

    goToRegisterPage() {
      const { studyCode, profileCode } = this.$route.params;
      this.$store.commit('studyCode', studyCode);
      this.$router.push(`/${profileCode}/register`);
    },
  },
};
</script>

<style scoped></style>
