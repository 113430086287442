<template>
  <div class="subscription-page" v-if="subscriptionProducts">
    <h2 class="subscription-page-title mb-4">Choisissez votre formule d'abonnement</h2>
    <div class="subscription-product-list mb-4">
      <div
        v-for="product in subscriptionProducts"
        :key="product.id"
        class="subscription-product-wrapper mb-3"
        :class="{ active: product === selectedProduct }"
        @click="selectedProduct = product"
      >
        {{ product.price.toFixed(2) }} € {{ product.name }}
      </div>
    </div>
    <div class="d-flex">
      <button class="btn btn-primary font-weight-bold" :disabled="!selectedProduct" @click="showCardForm = true">Confirmer la sélection</button>
      <button class="btn btn-primary font-weight-bold ml-3" @click="startFreeTrial()">Continuer l'essai gratuit</button>
    </div>
    <div v-if="showCardForm" class="payment-form my-5">
      <StripeElements
        :stripe-key="config.stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        #default="{ elements }"
        ref="elms"
      >
        <StripeElement type="card" :elements="elements" :options="cardOptions" ref="card" @change="onCardElementValueChanged" />
        <CInput
            type="text"
            class="mt-3"
            v-model="name"
            placeholder="First and last name"
            :addInputClasses="{
              'is-valid': name,
              'is-invalid': !name,
            }"
          />
      </StripeElements>
      <button class="btn btn-primary mt-5 btn-payment" :disabled="!isCardDataValid || !name" @click="startPaymentProcess" type="button">
        <span v-if="!isPaymentInProgress" class="text-white">Pay</span>
        <span v-if="isPaymentInProgress" class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus';
import config from '@/config';

export default {
  name: 'Subscription',
  components: {
    StripeElements,
    StripeElement,
  },
  data() {
    return {
      name: '',
      config,
      selectedProduct: null,
      showCardForm: false,
      isPaymentInProgress: false,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        value: {},
        style: {
          base: {
            height: '38px',
            padding: '10px',
            fontSize: '16px',
          },
          invalid: {
            color: '#ffc7ee',
          },
          complete: {
            color: 'green',
          },
        },
      },
      isCardDataValid: false,
    };
  },
  async mounted() {
    await this.getSubscriptionProducts();
  },
  computed: {
    subscriptionProducts() {
      return this.$store.state.subscriptionProducts;
    },
  },
  methods: {
    getSubscriptionProducts() {
      return this.$store.dispatch('getSubscriptionProducts');
    },
    async startPaymentProcess() {
      // ref in template
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;

      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      this.isPaymentInProgress = true;

      let tokenId = '';

      try {
        const stripeCardCreationResult = await groupComponent.instance.createToken(cardElement, {
          name: this.name,
        });

        const tokenData = stripeCardCreationResult && stripeCardCreationResult.token;

        if (!tokenData) {
          const providerErrorMessage
            = stripeCardCreationResult && stripeCardCreationResult.error && stripeCardCreationResult.error.message
              ? stripeCardCreationResult.error.message
              : '';

          this.$notify({
            title: providerErrorMessage || 'Failed to create the Stripe token',
            type: 'error',
          });

          this.isPaymentInProgress = false;

          return;
        }

        tokenId = tokenData.id;
      } catch (e) {
        console.dir(e);

        this.$notify({
          title: 'Failed to proceed with the payment for the entered card info',
          type: 'error',
        });

        this.isPaymentInProgress = false;
        console.warn(e);

        return;
      }

      try {
        await this.$http.post(`/api/subscription_product/${this.selectedProduct.id}/subscribe`, {
          token: tokenId,
        });

        this.isPaymentInProgress = false;
        this.$store.dispatch('refreshUser');

        setTimeout(() => {
          let destinationRoute = '/app/programs';

          if (this.$route.query.pendingProgramId) {
            destinationRoute += `?pendingProgramId=${this.$route.query.pendingProgramId}`;
          }

          this.$router.push(destinationRoute);
        });
      } catch (e) {
        this.$notify({
          title: 'Failed to handle the subscription',
          type: 'error',
        });

        this.isPaymentInProgress = false;
        console.warn(e);
      }
    },
    onCardElementValueChanged(event) {
      this.isCardDataValid = !!event.complete;
    },
    startFreeTrial() {
      const { user } = this.$store.state;

      this.$http
        .put(`/api/user/${user.id}`, {
          isFreeTrialInProgress: true,
        })
        .then(() => {
          this.$store.dispatch('refreshUser');
          setTimeout(() => {
            this.$router.push('/app/programs');
          });
        });
    },
  },
};
</script>
