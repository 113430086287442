<template>
  <CRow class="pb-3 flex-nowrap overflow-auto" :class="rowClass">
    <CCol
      :col="col || 9"
      :sm="sm || col"
      :md="md || sm || col"
      :lg="lg || md || sm || col"
      :xl="xl || lg || md || sm || col"
      v-for="(media, key) in medias"
      :key="key"
      class="col-program-day col-media"
    >
      <mediaCard :media="media" />
    </CCol>
  </CRow>
</template>

<script>
import mediaCard from '@/components/widgets/MediaCard.vue';

export default {
  name: 'media',
  props: [
    'type',
    'programId',
    'limit',
    'sort',
    'rowClass',
    'col',
    'sm',
    'md',
    'lg',
    'xl',
    'favorites',
  ],
  components: {
    mediaCard,
  },
  data() {
    return {
      medias: [],
      isFavorite: false,
    };
  },
  async mounted() {
    await this.getMedias();
  },
  methods: {
    async getMedias() {
      let filters = '';
      if (this.type) {
        filters = filters === '' ? filters : `${filters}&`;
        filters = `${filters}filters[type]=${this.type}`;
      }
      if (this.programId) {
        filters = filters === '' ? filters : `${filters}&`;
        filters = `${filters}filters[programId]=${this.programId}`;
      }
      if (this.limit) {
        filters = filters === '' ? filters : `${filters}&`;
        filters = `${filters}perPage=${this.limit}`;
      }
      if (this.sort) {
        filters = filters === '' ? filters : `${filters}&`;
        filters = `${filters}sort[${this.sort}]=DESC`;
      }
      if (this.favorites) {
        filters = filters === '' ? filters : `${filters}&`;
        filters = `${filters}favorites=true`;
      }
      const { data } = await this.$http.get(`/api/media?${filters}`);
      if (data && data.body) {
        this.medias = data.body;
      }
    },
  },
};
</script>
