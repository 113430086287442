<template>
  <div class="program-page" v-if="$store.state.programs">
    <div v-if="isTutorialModalDisplayed" class="program-page-overlay" />
    <CModal
      v-if="isTutorialModalDisplayed"
      :title="$t('common.modals.infoTitle')"
      :show.sync="showTutorialModal"
      :centered="true"
      size="md"
      @update:show="completeTutorial"
    >
      <div>{{ $t('common.labels.program-tutorial-1') }}</div>
      <div>{{ $t('common.labels.program-tutorial-2') }}</div>
      <div>{{ $t('common.labels.program-tutorial-3') }}</div>
      <template #header>
        <h5 class="modal-title">{{ $t('common.modals.infoTitle') }}</h5>
        <CButton @click="completeTutorial" class="close">
          <CIcon :src="closeIcon" />
        </CButton>
      </template>
    </CModal>
    <div class="program-info-wrapper mb-4">
      <div class="current-program-info">
        <div class="program-label">Mon programme</div>
        <div v-if="selectedProgram" class="program-name mt-2">{{ selectedProgram.title }}</div>
        <div v-if="!selectedProgram" class="program-name mt-2">Vous êtes actuellement sur le programme d'essai gratuit</div>
      </div>
      <button type="button" class="btn btn-info btn-fill" @click="changeProgram">Changer</button>
    </div>
    <CRow v-if="programWeeks.length">
      <CCol>
        <div class="program-week-list">
          <div
            v-for="(programWeekItem, index) in programWeeks"
            :key="index"
            class="program-week-button"
            :class="{ active: index === programWeekIndex }"
            @click="selectProgramWeek(index)"
          >
            Semaine {{ index + 1 }}
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="selectedProgramWeek" class="mt-4">
      <CCol>
        <div class="program-week-title">Semaine {{ programWeekIndex + 1 }}</div>
        <div
          v-for="(programDay, index) in selectedProgramWeek"
          :key="programDay.index"
          class="program-day-wrapper mt-3"
          :class="{ locked: isProgramDayLocked(programDay) }"
        >
          <div class="program-day-button" :class="{ active: index === programDayIndex }" @click="selectProgramDay(index)">
            <img v-if="index === programDayIndex" class="program-day-icon-active" :src="programDayActiveIcon" />
            <img v-if="index !== programDayIndex" class="program-day-icon" :src="programDayIcon" />
            <div class="program-day-title ml-3">Jour {{ index + 1 }}</div>
          </div>
          <CRow
            v-if="index === programDayIndex && programDay && programDay.ProgramDayMedias && programDay.ProgramDayMedias.length"
            class="pb-4 flex-wrap overflow-auto"
          >
            <CCol col="12" sm="12" md="6" xl="4" v-for="(item, key) in selectedProgramDay.ProgramDayMedias" :key="key" class="col-program-day mb-3">
              <div
                class="card card-program-day-media"
                :style="{ background: getCardColorByProgramDayMedia(item, true) }"
                @click="openProgramDayMedia(item)"
              >
                <div class="card-body">
                  <div class="card-img-container">
                    <img class="card-img" :src="mediaCardImage" alt="Media card image" />
                  </div>
                  <div class="card-info-wrapper ml-3">
                    <div
                      v-if="item.Media.Tag && item.Media.Tag.title"
                      class="card-tag"
                      :style="{ background: isProgramDayMediaCompleted(item) ? getCardColorByProgramDayMedia(item) : 'white' }"
                    >
                      {{ item.Media.Tag.title }}
                    </div>
                    <h5 class="card-title mt-2 mb-3">{{ item.Media.title }}</h5>
                    <div class="media-extra-info">
                      <img :src="getProgramDayMediaIcon(item)" />
                      <div class="separator"></div>
                      <b>{{ convertDurationToHoursAndMinutes(getProgramDayMediaDuration(item)) }}</b>
                      <div v-if="item.isOptional || getMediaCompletionDate(item)" class="separator"></div>
                      <div v-if="!getMediaCompletionDate(item) && item.isOptional" class="optional-label">Optionnel</div>
                      <b v-if="getMediaCompletionDate(item)"><div class="optional-label">Lu le {{ getMediaCompletionDate(item) }}</div></b>
                    </div>
                  </div>
                </div>
                <img v-if="isProgramDayLocked(programDay)" :src="lockIcon" class="lock-icon" />
                <div
                  v-if="!isProgramDayLocked(programDay) && isProgramDayMediaCompleted(item)"
                  class="media-completion-wrapper"
                  :style="{ background: getCardColorByProgramDayMedia(item) }"
                >
                  <img :src="checkIcon" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow v-if="programDay && programDay.ProgramDayMedias && !programDay.ProgramDayMedias.length">
            <CCol>
              <div>No media found for the given day</div>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import _ from 'lodash';
import * as moment from 'moment';
import utils from '../../utils';

import nutritionImg from '../../assets/images/nutrition.svg';
import formeImg from '../../assets/images/forme.svg';
import equilibreImg from '../../assets/images/equilibre.svg';
import phytotherapieImg from '../../assets/images/phytotherapie.svg';
import clockIcon from '../../assets/icons/Clock.svg';
import checkListIcon from '../../assets/icons/CheckList.svg';
import magicIcon from '../../assets/icons/magic.svg';
import playlistIcon from '../../assets/icons/playlist.svg';
import closeIcon from '../../assets/icons/Close.svg';
import lockIcon from '../../assets/icons/padlock.svg';
import checkIcon from '../../assets/icons/check.svg';
import fileIcon from '../../assets/icons/file.svg';
import programDayIcon from '../../assets/images/program-day.svg';
import programDayActiveIcon from '../../assets/images/program-day-active.svg';
import mediaCardImage from '../../assets/images/media-card-image.png';

export default {
  name: 'Programs',
  data() {
    return {
      programDays: [],
      medias: [],
      programImages: [nutritionImg, formeImg, equilibreImg, phytotherapieImg],
      clockIcon,
      checkListIcon,
      closeIcon,
      lockIcon,
      checkIcon,
      fileIcon,
      magicIcon,
      playlistIcon,
      programDayIcon,
      programDayActiveIcon,
      mediaCardImage,
      showTutorialModal: true,
      programWeeks: [],
      programWeekIndex: null,
      programDayIndex: null,
    };
  },
  async mounted() {
    this.$store.dispatch('refreshUser');

    await this.getPrograms();
    const { user } = this.$store.state;
    const { pendingProgramId } = this.$route.query;

    const trialProgram = this.programs.find((m) => m && m.isTrial);
    const trialProgramId = trialProgram ? trialProgram.id : null;

    if (this.hasUserActiveSubscription) {
      const programId = +pendingProgramId || user.programId || trialProgramId;
      const currentProgram = programId ? this.programs.find((m) => m && m.id === programId) : null;
      const userProgram = currentProgram || trialProgram;

      this.selectProgram(userProgram);

      await this.$http.put(`/api/user/${user.id}`, {
        isFreeTrialInProgress: false,
        programId: userProgram ? userProgram.id : null,
      });
    } else {
      this.selectProgram(trialProgram);

      const isUserUpdateRequired = !user.isFreeTrialInProgress || user.programId !== trialProgramId;

      if (isUserUpdateRequired) {
        await this.$http.put(`/api/user/${user.id}`, {
          isFreeTrialInProgress: true,
          programId: trialProgramId,
        });
      }
    }

    await this.getProgramDays();

    if (pendingProgramId) {
      this.$router.replace('/app/programs');
    }

    this.$store.dispatch('refreshUser');
  },
  computed: {
    programs() {
      return this.$store.state.programs;
    },

    selectedProgram() {
      return this.$store.state.selectedProgram;
    },

    isTutorialModalDisplayed() {
      // return this.$store.state.user && !this.$store.state.user.tutorialCompleted;
      return false;
    },

    hasUserActiveSubscription() {
      const { user } = this.$store.state;

      if (!user) {
        return false;
      }

      return (
        this.$store.state.user
        && this.$store.state.user.Subscriptions
        && this.$store.state.user.Subscriptions[0]
        && moment(this.$store.state.user.Subscriptions[0].activeUntil).isAfter()
      );
    },

    selectedProgramWeek() {
      if (this.programWeekIndex === undefined || !this.programWeeks) {
        return false;
      }

      return this.programWeeks[this.programWeekIndex];
    },

    selectedProgramDay() {
      if (this.programDayIndex === undefined || !this.selectedProgramWeek) {
        return false;
      }

      return this.selectedProgramWeek[this.programDayIndex];
    },
  },
  watch: {
    // eslint-disable-next-line
    '$store.state.selectedProgram': function () {
      this.getProgramDays();
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    getCardColorByProgramDayMedia: utils.getCardColorByProgramDayMedia,
    isProgramDayMediaCompleted: utils.isProgramDayMediaCompleted,
    roundFloatValue: utils.roundFloatValue,

    async completeTutorial() {
      const { user } = this.$store.state;
      if (!user) {
        return;
      }

      await this.$http.put(`/api/user/${user.id}`, {
        tutorialCompleted: true,
      });

      this.$store.dispatch('refreshUser');
      this.showTutorialModal = false;
    },

    getProgramImage(index) {
      if (!this.programs || !this.programs[index]) {
        return;
      }

      const program = this.programs[index];

      return program.image || this.programImages[index % this.programs.length];
    },

    async openProgramDayMedia(programDayMedia) {
      if (!programDayMedia) {
        return;
      }

      this.$router.push(`/app/program-day-media/${programDayMedia.id}`);
    },

    getProgramDayMediaIcon(programDayMedia) {
      if (!programDayMedia || !programDayMedia.Media || !programDayMedia.Media.type) {
        return this.fileIcon;
      }

      switch (programDayMedia.Media.type) {
        case 'podcast':
        case 'video':
        case 'recipe':
          return this.playlistIcon;
        case 'quiz':
          return this.magicIcon;
        case 'page':
        case 'html':
        default:
          return this.fileIcon;
      }
    },

    async selectProgram(idOrObject) {
      if (!idOrObject) {
        return;
      }
      const program = _.isObject(idOrObject) ? idOrObject : this.$store.state.programs.find((m) => m && m.id === idOrObject);
      this.$store.commit('selectedProgram', program);
    },

    getPrograms() {
      return this.$store.dispatch('getPrograms');
    },

    async getProgramDays() {
      if (!this.$store.state.selectedProgram) {
        return;
      }

      const { data } = await this.$http.get(
        `/api/program_day?filters[programId]=${this.$store.state.selectedProgram.id}&filters[isPublished]=1&sort[order]=ASC`,
      );

      const programDays = data && data.body ? data && data.body : [];

      const filteredProgramDays = programDays
        .filter((programDay) => {
          if (!programDay || !programDay.isPublished || !programDay.ProgramDayMedias || !programDay.ProgramDayMedias.length) {
            return false;
          }

          programDay.ProgramDayMedias = (programDay.ProgramDayMedias || []).filter((programDayMedia) => programDayMedia && programDayMedia.Media);

          return !!programDay.ProgramDayMedias.length;
        })
        .sort((dayA, dayB) => {
          if (dayA.order === dayB.order) {
            return dayA.id - dayB;
          }

          return dayA.order - dayB.order;
        });

      this.programDays = filteredProgramDays;

      this.programWeeks = _.chunk(filteredProgramDays, 7);

      let programWeekIndex = 0;
      let programDayIndex = 0;

      for (let i = 0; i < filteredProgramDays.length; i++) {
        const day = filteredProgramDays[i];

        if (day.UserProgramDays && day.UserProgramDays.length) {
          programDayIndex = i;

          const activeWeekIndex = this.programWeeks.findIndex((item) => item.includes(day));

          if (activeWeekIndex !== -1) {
            programWeekIndex = activeWeekIndex;
          }
        }
      }

      this.programWeekIndex = programWeekIndex;
      this.programDayIndex = programDayIndex;
    },

    selectProgramWeek(weekIndex) {
      if (weekIndex !== this.programWeekIndex) {
        this.programDayIndex = 0;
      }

      this.programWeekIndex = weekIndex;
    },

    selectProgramDay(dayIndex) {
      this.programDayIndex = dayIndex;
    },

    isProgramDayLocked(programDay) {
      if (!programDay || !programDay.UserProgramDays || !programDay.UserProgramDays.length) {
        return true;
      }

      return false;
    },

    changeProgram() {
      this.$router.push('/app/select-program');
    },

    getMediaCompletionDate(programDayMedia) {
      if (!programDayMedia || !programDayMedia.UsersProgressions || !programDayMedia.UsersProgressions.length || !programDayMedia.UsersProgressions[0].completedOn) {
        return '';
      }

      return moment(programDayMedia.UsersProgressions[0].completedOn).format('DD/MM/YYYY');
    },

    getProgramDayMediaDuration(programDayMedia) {
      if (!programDayMedia || !programDayMedia.Media) {
        return 0;
      }

      if (programDayMedia.Media.Quiz && programDayMedia.Media.Quiz.estimatedDuration) {
        return programDayMedia.Media.Quiz.estimatedDuration;
      }

      return programDayMedia.Media.estimatedDuration || 0;
    }
  },
};
</script>

<style scoped>
.card-footer .btn-block {
  border-radius: 0px;
}
</style>
