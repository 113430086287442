var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.programs)?_c('div',{staticClass:"page-medias tab-content"},_vm._l((['video', 'podcast']),function(type){return _c('div',{key:type,staticClass:"tab-pane fade",class:type === _vm.selectedMediaTab ? 'show active' : '',attrs:{"id":"","role":"tabpanel"}},[_c('div',[_c('h3',{staticClass:"text-primary mb-3 media-section-title"},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$tc('common.labels.' + type, 2))+" les plus vues")]),_vm._v(" "),_c('a',{staticClass:"pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer",on:{"click":() =>
              _vm.$router.push({
                name: 'MediasList',
                params: {
                  title: `${_vm.$tc('common.labels.' + type, 2)} tendances`,
                  type: type,
                  sort: 'viewsCount',
                },
              })}},[_vm._v("\n          Voir + "),_c('span',{staticClass:"align-middle text-primary"},[_vm._v("→")])])]),_vm._v(" "),_c('mediaWidget',{attrs:{"type":type,"sort":"viewsCount","limit":"4","sm":"5","md":"4","xl":"3"}})],1),_vm._v(" "),_c('div',[_c('h3',{staticClass:"text-primary mt-4 mb-3 media-section-title"},[(type === 'podcast')?_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$tc('common.labels.' + type, 2))+" les plus récents")]):_vm._e(),_vm._v(" "),(type === 'video')?_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$tc('common.labels.' + type, 2))+" les plus récentes")]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer",on:{"click":() =>
              _vm.$router.push({
                name: 'MediasList',
                params: {
                  title: `${_vm.$tc('common.labels.' + type, 2)} les plus récentes`,
                  type: type,
                },
              })}},[_vm._v("\n          Voir + "),_c('span',{staticClass:"align-middle text-primary"},[_vm._v("→")])])]),_vm._v(" "),_c('mediaWidget',{attrs:{"type":type,"sort":"createdOn","limit":"4","sm":"5","md":"4","xl":"3"}})],1),_vm._v(" "),_vm._l((_vm.$store.state.programs),function(program){return _c('div',{key:type + program.title},[_c('h3',{staticClass:"text-primary mt-4 mb-3 media-section-title"},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(program.title))]),_vm._v(" "),_c('a',{staticClass:"pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer",on:{"click":() =>
              _vm.$router.push({
                name: 'MediasList',
                params: {
                  title: program.title,
                  type: type,
                  programId: program.id,
                },
              })}},[_vm._v("\n          Voir + "),_c('span',{staticClass:"align-middle text-primary"},[_vm._v("→")])])]),_vm._v(" "),_c('mediaWidget',{attrs:{"type":type,"programId":program.id,"sort":"lastModifiedOn","limit":"4","sm":"5","md":"4","xl":"3"}})],1)}),_vm._v(" "),_c('div',[_c('h3',{staticClass:"text-primary mt-4 mb-3 media-section-title"},[_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$tc('common.labels.' + type, 2))+" en favoris")]),_vm._v(" "),_c('a',{staticClass:"pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer",on:{"click":() =>
              _vm.$router.push({
                name: 'MediasList',
                params: {
                  title: `${_vm.$tc('common.labels.' + type, 2)} en favoris`,
                  type: type,
                  favorites: true,
                },
              })}},[_vm._v("\n          Voir + "),_c('span',{staticClass:"align-middle text-primary"},[_vm._v("→")])])]),_vm._v(" "),_c('mediaWidget',{attrs:{"type":type,"favorites":"true","limit":"4","sm":"5","md":"4","xl":"3"}})],1)],2)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }