<template>
  <div class="page-medias tab-content" v-if="$store.state.programs">
    <div
      class="tab-pane fade"
      :class="type === selectedMediaTab ? 'show active' : ''"
      v-for="type in ['video', 'podcast']"
      :key="type"
      id=""
      role="tabpanel"
    >
      <div>
        <h3 class="text-primary mb-3 media-section-title">
          <span class="text-primary">{{ $tc('common.labels.' + type, 2) }} les plus vues</span>
          <a
            @click="
              () =>
                $router.push({
                  name: 'MediasList',
                  params: {
                    title: `${$tc('common.labels.' + type, 2)} tendances`,
                    type: type,
                    sort: 'viewsCount',
                  },
                })
            "
            class="pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer"
          >
            Voir + <span class="align-middle text-primary">→</span>
          </a>
        </h3>
        <mediaWidget :type="type" sort="viewsCount" limit="4" sm="5" md="4" xl="3" />
      </div>
      <div>
        <h3 class="text-primary mt-4 mb-3 media-section-title">
          <span v-if="type === 'podcast'" class="text-primary"
            >{{ $tc('common.labels.' + type, 2) }} les plus récents</span
          >
          <span v-if="type === 'video'" class="text-primary"
            >{{ $tc('common.labels.' + type, 2) }} les plus récentes</span
          >
          <a
            @click="
              () =>
                $router.push({
                  name: 'MediasList',
                  params: {
                    title: `${$tc('common.labels.' + type, 2)} les plus récentes`,
                    type: type,
                  },
                })
            "
            class="pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer"
          >
            Voir + <span class="align-middle text-primary">→</span>
          </a>
        </h3>
        <mediaWidget :type="type" sort="createdOn" limit="4" sm="5" md="4" xl="3" />
      </div>
      <div v-for="program in $store.state.programs" :key="type + program.title">
        <h3 class="text-primary mt-4 mb-3 media-section-title">
          <span class="text-primary">{{ program.title }}</span>
          <a
            @click="
              () =>
                $router.push({
                  name: 'MediasList',
                  params: {
                    title: program.title,
                    type: type,
                    programId: program.id,
                  },
                })
            "
            class="pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer"
          >
            Voir + <span class="align-middle text-primary">→</span>
          </a>
        </h3>
        <mediaWidget
          :type="type"
          :programId="program.id"
          sort="lastModifiedOn"
          limit="4"
          sm="5"
          md="4"
          xl="3"
        />
      </div>
      <div>
        <h3 class="text-primary mt-4 mb-3 media-section-title">
          <span class="text-primary">{{ $tc('common.labels.' + type, 2) }} en favoris</span>
          <a
            @click="
              () =>
                $router.push({
                  name: 'MediasList',
                  params: {
                    title: `${$tc('common.labels.' + type, 2)} en favoris`,
                    type: type,
                    favorites: true,
                  },
                })
            "
            class="pr-1 pt-1 align-middle font-weight-light float-right f-16 cursor-pointer"
          >
            Voir + <span class="align-middle text-primary">→</span>
          </a>
        </h3>
        <mediaWidget :type="type" favorites="true" limit="4" sm="5" md="4" xl="3" />
      </div>
    </div>
  </div>
</template>

<script>
import mediaWidget from '@/components/widgets/Media.vue';

export default {
  name: 'Medias',
  components: {
    mediaWidget,
  },
  data() {
    return {};
  },
  computed: {
    selectedMediaTab() {
      return this.$store.state.selectedMediaTab;
    },
  },
};
</script>
