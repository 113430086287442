<template>
  <div class="nav-dock d-lg-none">
    <div class="nav-dock-links">
      <div
        class="nav-dock-link"
        :class="{ active: currentActiveLink === 'dashboard' }"
        @click="() => goToRoute('/app/dashboard')"
      >
        <CIcon class="nav-dock-image" :src="homeIcon" />
        <div class="nab-dock-label">{{ $t('common.buttons.home') }}</div>
      </div>
      <div
        class="nav-dock-link"
        :class="{ active: currentActiveLink === 'programs' }"
        @click="() => goToRoute('/app/programs')"
      >
        <CIcon class="nav-dock-image" :src="programIcon" />
        <div class="nab-dock-label">{{ $t('common.labels.programs') }}</div>
      </div>
      <div
        class="nav-dock-link"
        :class="{ active: currentActiveLink === 'reports' }"
        @click="() => goToRoute('/app/reports')"
      >
        <CIcon class="nav-dock-image" :src="reportsIcon" />
        <div class="nab-dock-label">{{ $t('common.labels.indicators') }}</div>
      </div>
      <div
        class="nav-dock-link"
        :class="{ active: currentActiveLink === 'goals' }"
        @click="() => goToRoute('/app/goals')"
      >
        <CIcon class="nav-dock-image" :src="objectivesIcon" />
        <div class="nab-dock-label">{{ $t('common.labels.objectives') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import homeIcon from '@/assets/icons/Home.svg';
import programIcon from '@/assets/icons/Programmes.svg';
import reportsIcon from '@/assets/icons/Reports.svg';
import objectivesIcon from '@/assets/icons/Objectives.svg';

export default {
  name: 'Dock',
  computed: {
    currentActiveLink() {
      return this.$route.path.split('/')[2] && this.$route.path.split('/')[2] !== ''
        ? this.$route.path.split('/')[2]
        : '';
    },
  },
  data: () => ({
    homeIcon,
    programIcon,
    reportsIcon,
    objectivesIcon,
  }),
  methods: {
    goToRoute(newRoute) {
      this.$router.push(newRoute);
      this.$store.commit('set', ['sidebarShow', false]);

      if ($('#reportFormModal').hasClass('show')) {
        $('#reportFormModal').modal('hide');
      }
    },
  },
};
</script>
