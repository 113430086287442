<template>
  <CCard class="p-0 card-dashboard">
    <CCardHeader><span class="f-18">Mon tableau de bord</span></CCardHeader>
    <CCardBody>
      <div class="row justify-content-center">
        <div class="card col-5 shadow-none bg-default text-center py-2 px-0 mx-2">
          <p class="font-weight-bold f-30 m-0 text-primary">{{ progressionTime }}</p>
          <div class="label">
            <CIcon class="align-middle" :src="clockIcon"></CIcon>
            <span class="align-middle">Temps passé</span>
          </div>
        </div>
        <div class="card col-5 shadow-none bg-default text-center py-2 px-0 mx-2">
          <p class="font-weight-bold f-30 m-0 text-primary">{{ roundFloatValue(progress || 0, 0) }}%</p>
          <div class="label">
            <CIcon class="align-middle" :src="progressIcon"></CIcon>
            <span class="align-middle">Progression</span>
          </div>
        </div>
        <div class="card col-5 shadow-none bg-default text-center py-2 px-0 mx-2">
          <p class="font-weight-bold f-30 m-0 text-primary">{{ goalAmount }}</p>
          <div class="label">
            <CIcon class="align-middle" :src="cupIcon"></CIcon>
            <span class="align-middle">Objectifs</span>
          </div>
        </div>
        <div class="card col-5 shadow-none bg-default text-center py-2 px-0 mx-2">
          <p class="font-weight-bold f-30 m-0 text-primary">{{ badgeAmount }}</p>
          <div class="label">
            <CIcon class="align-middle" :src="badgeIcon"></CIcon>
            <span class="align-middle">Badges</span>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import utils from '../../utils';

import clockIcon from '../../assets/icons/Clock.svg';
import progressIcon from '../../assets/icons/Progress.svg';
import cupIcon from '../../assets/icons/Cup.svg';
import badgeIcon from '../../assets/icons/Badge.svg';

export default {
  name: 'dashboard',
  data() {
    return {
      clockIcon,
      progressIcon,
      cupIcon,
      badgeIcon,
      stats: null,
    };
  },
  async mounted() {
    await this.getStats();
  },
  computed: {
    badgeAmount() {
      return this.stats && this.stats.userBadgeAmount ? this.stats.userBadgeAmount : 0;
    },
    goalAmount() {
      return this.stats && this.stats.achievedUserGoalsAmount ? this.stats.achievedUserGoalsAmount : 0;
    },
    progressionTime() {
      return this.stats && this.stats.progressionTime ? this.convertDurationToHoursAndMinutes(this.stats.progressionTime) : 0;
    },
    progress() {
      return this.stats && this.stats.progress ? this.stats.progress : 0;
    },
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    roundFloatValue: utils.roundFloatValue,
    async getStats() {
      const { data } = await this.$http.get('/api/stats/dashboard/general');
      if (data && data.body) {
        this.stats = data.body;
      }
    },
  },
};
</script>
