<template>
  <div
    class="modal fade"
    id="mediaModal"
    ref="mediaModalRef"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mediaModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content h-100 v-100 p-0">
        <div class="modal-body p-0 h-100">
          <button
            type="button"
            class="close text-white"
            aria-label="Close"
            @click="close()"
            data-
            style="z-index: 1000; color: white"
          >
            <label aria-hidden="true" class="text-light font-bold" style="font-size: 30px">×</label>
          </button>

          <div v-if="media" class="media" :class="mediaFrom || 'other'">
            <iframe
              v-if="mediaFrom === 'youtube' && clicked"
              :src="'https://www.youtube.com/embed/' + iframeSrc + '?autoplay=1'"
              height="100%"
              width="100%"
              frameborder="0"
            ></iframe>
            <iframe
              v-if="mediaFrom === 'vimeo' && clicked"
              :src="
                'https://player.vimeo.com/video/' +
                iframeSrc +
                '?color=47776E&title=0&byline=0&portrait=0&autoplay=1'
              "
              height="100%"
              width="100%"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
            <iframe
              v-if="mediaFrom === 'soundcloud' && clicked"
              height="100%"
              width="100%"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              :src="
                'https://w.soundcloud.com/player/?url=' +
                media.mainMediaUrl +
                '&color=47776E&auto_play=true'
              "
            ></iframe>
            <iframe
              v-if="!mediaFrom && clicked"
              height="100%"
              width="100%"
              scrolling="no"
              frameborder="no"
              allow="autoplay"
              :src="media.mainMediaUrl || media.mainMedia"
            ></iframe>
            <div
              v-if="!clicked"
              class="h-100 w-100 fakePlayer"
              @click="play()"
              :style="{ 'background-image': 'url(' + media.thumbnail + ')' }"
            >
              <div class="fakePlayerButtons">
                <CIcon :src="playIcon" class="ml-3 mr-3" />
                <span class="text-white align-middle">0:00</span>
                <CIcon :src="zoomIcon" class="float-right ml-4 mr-3" />
                <CIcon :src="volumeIcon" class="float-right" />
              </div>
            </div>
          </div>
          <div v-if="media" class="info f-12">
            <div class="row m-0 p-2 align-items-center">
              <div :style="{ 'background-color': media.Program.color }" class="media-tag">
                {{ media.Program.title }}
              </div>
              <div class="ml-2">{{ date }}</div>
              <MediaFavoriteIcon :mediaId="media.id" />
            </div>
            <div class="row m-0 p-2">
              <h4 class="f-18 m-0">{{ media.title }}</h4>
            </div>
            <div class="row m-0 p-2">
              <div class="text-left" v-html="media.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIdFromUrl } from 'vue-youtube';
import moment from 'moment';
import MediaFavoriteIcon from '@/components/widgets/MediaFavoriteIcon.vue';
import playIcon from '@/assets/icons/play.svg';
import zoomIcon from '@/assets/icons/Zoom.svg';
import volumeIcon from '@/assets/icons/Volume.svg';

export default {
  name: 'mediaModal',
  components: {
    MediaFavoriteIcon,
  },
  data() {
    return {
      isHiddenListenerRegistered: false,
      clicked: false,
      playIcon,
      zoomIcon,
      volumeIcon,
    };
  },
  updated() {
    this.$nextTick(() => {
      if (this.isModalHiddenListenerRegistered) {
        return;
      }

      const modalRef = this.$refs.mediaModalRef;
      if (!modalRef) {
        return;
      }

      $(modalRef).on('hidden.bs.modal', this.onModalClosed);
      this.isModalHiddenListenerRegistered = true;
    });
  },
  beforeDestroy() {
    const modalRef = this.$refs.mediaModalRef;
    if (!modalRef) {
      return;
    }

    $(modalRef).off('hidden.bs.modal', this.onModalClosed);
  },
  computed: {
    media() {
      return this.$store.state.mediaModalContent;
    },
    mediaFrom() {
      if (this.media.mainMediaUrl && this.media.mainMediaUrl.includes('youtu')) {
        return 'youtube';
      }
      if (this.media.mainMediaUrl && this.media.mainMediaUrl.includes('vimeo')) {
        return 'vimeo';
      }
      if (this.media.mainMediaUrl && this.media.mainMediaUrl.includes('soundcloud')) {
        return 'soundcloud';
      }
      return '';
    },
    iframeSrc() {
      if (this.media.mainMediaUrl && this.media.mainMediaUrl.includes('youtu')) {
        return `${getIdFromUrl(this.media.mainMediaUrl)}`;
      }
      if (this.media.mainMediaUrl && this.media.mainMediaUrl.includes('vimeo')) {
        return this.media.mainMediaUrl.match(/\d{6,}/g)[0];
      }
      return '';
    },
    date() {
      return moment(String(this.media.createdOn)).format('DD/MM/YYYY');
    },
  },
  methods: {
    onModalClosed() {
      this.onVideoClicked();
      this.clicked = false;
      this.$store.commit('mediaModalContent', null);
    },
    onVideoClicked() {
      const { user } = this.$store.state;

      if (user) {
        if (!user.firstVideoViewed) {
          this.$http
            .put(`/api/user/${user.id}`, {
              firstVideoViewed: true,
            })
            .then(() => {
              this.$store.dispatch('refreshUser');
            });
        }
      }
    },
    play() {
      this.clicked = true;
      this.$http.get(`/api/media/play/${this.media.id}`);
      this.onVideoClicked();
    },
    close() {
      this.$nextTick(() => {
        $('#mediaModal').modal('hide');
      });
    },
  },
};
</script>
