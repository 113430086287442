<template>
  <div class="c-app page-login flex-row align-items-center" :class="{ dark: isOnboardingFinished && showInitialRegistrationScreen }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol v-if="!isOnboardingFinished" md="12" class="onboarding-wrapper">
          <div class="multistep-progress-bar">
            <div
              v-for="(onboardingStep, i) in onboardingSteps"
              :key="i"
              class="multistep-step"
              :class="{ active: i <= currentOnboardingStepIndex }"
              :style="{ width: `${onboardingSteps.length ? 100 / onboardingSteps.length : 100}%` }"
            ></div>
          </div>
          <div v-if="currentOnboardingStep" class="onboarding-content-wrapper">
            <img class="register-image" :src="medicalRecordImage" />
            <h3 class="mt-5">{{ currentOnboardingStep.title }}</h3>
            <h4 class="mt-4">{{ currentOnboardingStep.description }}</h4>
          </div>
          <div class="btn-wrapper">
            <CButton class="btn-confirm" @click="goToNextOnboardingStep">Suivant</CButton>
          </div>
        </CCol>

        <CCol v-if="isOnboardingFinished && showInitialRegistrationScreen" md="12" class="registration-welcome-wrapper">
          <div v-if="currentOnboardingStep" class="registration-welcome-content-wrapper">
            <img class="register-image" :src="medicalRecordImage" />
            <h3 class="mt-5">Prêt à activer le changement ?</h3>
            <h4 class="mt-4">Inscrivez vous et testez notre programme d'essai gratuit !</h4>
          </div>
          <div class="btn-wrapper">
            <CButton class="btn-confirm" @click="startRegistrationProcess">Commencer</CButton>
          </div>
        </CCol>

        <CCol v-if="isOnboardingFinished && showRegistrationForm" md="12" class="registration-wrapper">
          <div class="multistep-progress-bar">
            <div
              v-for="(registrationStep, i) in registrationSteps"
              :key="i"
              class="multistep-step"
              :class="{ active: i <= currentRegistrationStepIndex }"
              :style="{ width: `${registrationSteps.length ? 100 / registrationSteps.length : 100}%` }"
            ></div>
          </div>
          <div v-if="currentRegistrationStep" class="registration-content-wrapper">
            <h3 class="mt-5">{{ currentRegistrationStep.title }}</h3>
            <CInput
              v-if="currentRegistrationStep.step === 'firstName'"
              class="mt-5"
              type="text"
              required
              @keyup.enter="goToNextRegistrationStep"
              v-model="newUser.firstName"
              :addInputClasses="{
                'is-valid': newUser.firstName,
                'is-invalid': isFormSubmitted && !newUser.firstName,
              }"
              :placeholder="$t('common.placeholders.firstName')"
            />
            <CInput
              v-if="currentRegistrationStep.step === 'lastName'"
              class="mt-5"
              type="text"
              required
              @keyup.enter="goToNextRegistrationStep"
              :addInputClasses="{
                'is-valid': newUser.lastName,
                'is-invalid': !newUser.lastName,
              }"
              v-model="newUser.lastName"
              :placeholder="$t('common.placeholders.lastName')"
            />
            <div v-if="currentRegistrationStep.step === 'birthdate'" role="group" class="form-group mt-5">
              <input type="date" v-model="newUser.birthdate" required class="form-control datepicker-input" />
            </div>
            <CInput
              v-if="currentRegistrationStep.step === 'weight'"
              class="mt-5"
              type="number"
              @keyup.enter="goToNextRegistrationStep"
              :step="0.01"
              v-model="newUser.weight"
              :placeholder="$t('common.placeholders.weight')"
              append="kg"
            />
            <CInput
              v-if="currentRegistrationStep.step === 'height'"
              class="mt-5"
              type="number"
              @keyup.enter="goToNextRegistrationStep"
              :step="0.01"
              v-model="newUser.height"
              :placeholder="$t('common.placeholders.height')"
              append="cm"
            />
            <div v-if="currentRegistrationStep.step === 'profile'" class="profile-option-wrapper mt-5">
              <CInputRadioGroup custom :options="profileOptions" :checked.sync="newUser.profileCode" class="profile-option-list" required />
            </div>
            <CInput
              v-if="currentRegistrationStep.step === 'email'"
              class="mt-5"
              type="email"
              @keyup.enter="goToNextRegistrationStep"
              required
              v-model="newUser.email"
              autocomplete="email"
              :addInputClasses="{
                'is-valid': isEmailValid(),
                'is-invalid': !isEmailValid(),
              }"
              :placeholder="$t('common.placeholders.email')"
            />
            <CInput
              v-if="currentRegistrationStep.step === 'email'"
              class="mt-3"
              type="email"
              @keyup.enter="goToNextRegistrationStep"
              required
              v-model="newUser.confirmEmail"
              autocomplete="email"
              :addInputClasses="{
                'is-valid': newUser.email && newUser.email === newUser.confirmEmail,
                'is-invalid': !newUser.email || newUser.email !== newUser.confirmEmail,
              }"
              :placeholder="$t('common.placeholders.confirmEmail')"
            />
            <CInput
              v-if="currentRegistrationStep.step === 'password'"
              class="mt-5"
              type="password"
              @keyup.enter="goToNextRegistrationStep"
              required
              :addInputClasses="{
                'is-valid': isPasswordValid(newUser.password),
                'is-invalid': !isPasswordValid(newUser.password),
              }"
              v-model="newUser.password"
              autocomplete="current-password"
              :placeholder="$t('common.placeholders.password')"
            />
            <CInput
              v-if="currentRegistrationStep.step === 'password'"
              class="mt-3"
              type="password"
              @keyup.enter="goToNextRegistrationStep"
              required
              :addInputClasses="{
                'is-valid': newUser.password && newUser.password === newUser.confirmPassword,
                'is-invalid': !newUser.password || newUser.password !== newUser.confirmPassword,
              }"
              v-model="newUser.confirmPassword"
              autocomplete="current-password"
              :placeholder="$t('common.placeholders.confirmPassword')"
            />
            <div v-if="currentRegistrationStep.step === 'password'">{{ $t('common.labels.passwordPolicy') }}</div>
          </div>
          <div class="btn-wrapper">
            <CButton class="btn-confirm" :disabled="currentRegistrationStep === 0 || !isRegistrationFormValid()" @click="goToNextRegistrationStep"
              >Suivant</CButton
            >
          </div>
        </CCol>

        <CCol v-if="isOnboardingFinished && showLastRegistrationScreen" md="12" class="registration-confirm-wrapper">
          <div v-if="currentOnboardingStep" class="registration-confirm-content-wrapper">
            <img class="register-image" :src="medicalRecordImage" />
            <h3 class="mt-5">Merci d’avoir répondu à nos questions !</h3>
            <h4 class="mt-4">Vous pouvez maintenant découvrir notre programme d’essai pour découvrir les biens faits de Doclivi</h4>
          </div>
          <div class="btn-wrapper">
            <CButton class="btn-confirm" @click="registerUser">Commencer</CButton>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { apiErrorsMixin } from 'vue-aw-components';
import 'bootstrap-datepicker';
import _ from 'lodash';
import config from '@/config';
import medicalRecordImage from '../../assets/images/medical-record.svg';
import arrowLeftIcon from '../../assets/icons/arrow-left.svg';

export default {
  name: 'Register',
  components: {},
  mixins: [apiErrorsMixin],
  data() {
    return {
      profiles: [],
      currentOnboardingStepIndex: 0,
      currentRegistrationStepIndex: 0,
      showInitialRegistrationScreen: true,
      showRegistrationForm: false,
      showLastRegistrationScreen: false,
      onboardingSteps: [
        {
          title: 'Bienvenue !',
          description: "Bienvenue. Un nouveau jour se lève, porteur de promesses et d'opportunités. C'est le début d'une nouvelle aventure !",
        },
        {
          title: 'Titre',
          description: 'Accueillez cet allié dans votre parcours de transformation et de changement, conçu et validé par la science',
        },
        {
          title: 'Titre',
          description: 'Utilisez des outils variés, vous serez accompagnés chaque jour et progresserez pas à pas',
        },
      ],
      registrationSteps: [
        {
          title: 'Quel est votre prénom ?',
          step: 'firstName',
        },
        {
          title: 'Quel est votre nom ?',
          step: 'lastName',
        },
        {
          title: 'Quelle est votre date de naissance ?',
          step: 'birthdate',
        },
        {
          title: 'Quel est votre poids ?',
          step: 'weight',
        },
        {
          title: 'Quelle est votre taille ?',
          step: 'height',
        },
        {
          title: 'Quel est le programme qui vous intéresse ?',
          step: 'profile',
        },
        {
          title: 'Quel est votre email ?',
          step: 'email',
        },
        {
          title: 'Quel sera votre mot de passe ?',
          step: 'password',
        },
      ],
      medicalRecordImage,
      steps: [
        {
          name: 'generalInfo',
          label: this.$t('common.labels.generalInfoStepNameLeft'),
        },
        {
          name: 'personalData',
          label: this.$t('common.labels.personalDataStepName'),
        },
        {
          name: 'confirmation',
          label: this.$t('common.labels.confirmationDataStepName'),
        },
      ],
      isFormSubmitted: false,
      isRegisterButtonDisabled: false,
      isRequestInProgress: false,
      newUser: {
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
        gender: '',
        firstName: '',
        lastName: '',
        profileCode: '',
      },
      config,
      arrowLeftIcon,
      unregisterRouterHooksFn: null,
    };
  },
  mounted() {
    this.unregisterRouterHooksFn = this.$router.beforeEach((to, from, next) => {
      if (from.name === 'Register' && to.name !== 'Dashboard' && to.query.pass !== 'true') {
        next(false);
        this.handleBackButtonPressed(to.fullPath);
      } else {
        next();
      }
    });

    this.$router.afterEach((to, from) => {
      if (to.query.pass === 'true') {
        this.$router.replace(to.path);
      }
    });

    this.getProfiles();

    this.$http.get('api/auth/user').then((res) => {
      const { user } = res.data;
      if (user) {
        this.$router.push('/dashboard');
      }
    });
  },

  beforeDestroy() {
    if (this.unregisterRouterHooksFn) {
      this.unregisterRouterHooksFn();
    }
  },

  computed: {
    isOnboardingFinished() {
      return this.$store.state.isOnboardingFinished;
    },
    currentOnboardingStep() {
      return this.onboardingSteps[this.currentOnboardingStepIndex];
    },
    currentRegistrationStep() {
      return this.registrationSteps[this.currentRegistrationStepIndex];
    },
    email() {
      return this.newUser && this.newUser.email;
    },
    password() {
      return this.newUser && this.newUser.password;
    },
    profileOptions() {
      return this.profiles.map((profile) => ({
        label: profile.readableTitle || profile.title,
        value: profile.code,
      }));
    },
  },
  methods: {
    isEmailValid() {
      return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.email);
    },
    isPasswordValid(value) {
      return /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(value);
    },
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    registerUser() {
      this.$store.commit('user', '');
      this.$store.dispatch('logout');

      if (this.isRequestInProgress) {
        return;
      }

      if (
        !this.newUser.firstName
        || !this.newUser.lastName
        || !this.newUser.weight
        || !this.newUser.height
        || !this.newUser.birthdate
        || !this.newUser.email
        || !this.newUser.password
        || !this.newUser.profileCode
      ) {
        return;
      }

      this.$notifications.clear();
      this.isRequestInProgress = true;

      this.$http
        .post('/api/user', {
          ..._.pick(this.newUser, ['email', 'password', 'firstName', 'lastName', 'profileCode', 'weight', 'height']),
          studyCode: this.$store.state.studyCode,
          hasCompletedRegistration: true,
        })
        .then((res) => {
          this.$store.commit('studyCode', null);

          this.$notify({
            title: this.$t('common.messages.successfullyUpdated'),
            type: 'success',
          });

          this.isFormSubmitted = false;
          this.newUser = {};

          this.$store.dispatch('user', res.data.user);
          this.$store.commit('token', res.data.token);
          this.$store.dispatch('selectProfile');
        })
        .catch((err) => {
          this.isRequestInProgress = false;
          let toastMessage = "Une erreur s'est produite veuillez rafraichir la page";

          if (err.response.data.message === 'error_conflict_email') {
            toastMessage = 'Cette adresse E-mail existe déjà, cliquez sur Login pour vous connecter à la plate-forme';
          }

          this.$notify({
            title: toastMessage,
            type: 'warning',
          });
        })
        .then(() => {
          this.isRequestInProgress = false;
          this.openDashboard();
        });
    },
    openDashboard() {
      this.currentStep = null;
      this.$router.push('/dashboard');
    },
    async getProfiles() {
      const { data } = await this.$http.get('/api/profile');
      this.profiles = data && data.body ? data.body : [];
    },
    goToNextOnboardingStep() {
      if (this.currentOnboardingStepIndex === this.onboardingSteps.length - 1) {
        this.$store.commit('isOnboardingFinished', true);
        this.currentOnboardingStepIndex = 0;
        this.showInitialRegistrationScreen = true;
      } else {
        this.currentOnboardingStepIndex++;
      }
    },
    goToNextRegistrationStep() {
      if (!this.isRegistrationFormValid()) {
        return;
      }

      if (this.currentRegistrationStepIndex === this.registrationSteps.length - 1) {
        this.currentRegistrationStepIndex = 0;
        this.showRegistrationForm = false;
        this.showLastRegistrationScreen = true;
      } else {
        this.currentRegistrationStepIndex++;
      }
    },
    startRegistrationProcess() {
      this.showInitialRegistrationScreen = false;
      this.showRegistrationForm = true;
    },
    isRegistrationFormValid() {
      const registrationStep = this.currentRegistrationStep;

      if (!registrationStep) {
        return false;
      }

      switch (registrationStep.step) {
        case 'firstName':
          return !!this.newUser.firstName;
        case 'lastName':
          return !!this.newUser.lastName;
        case 'birthdate':
          return !!this.newUser.birthdate;
        case 'weight':
          return !!this.newUser.weight;
        case 'height':
          return !!this.newUser.height;
        case 'profile':
          return !!this.newUser.profileCode;
        case 'email':
          return this.isEmailValid() && this.newUser.email === this.newUser.confirmEmail;
        case 'password':
          return this.isPasswordValid(this.newUser.password) && this.newUser.password === this.newUser.confirmPassword;
        default:
          return true;
      }
    },

    handleBackButtonPressed(destinationRoute) {
      if (this.isOnboardingFinished) {
        if (this.showLastRegistrationScreen) {
          this.showLastRegistrationScreen = false;
          this.showRegistrationForm = true;
          this.currentRegistrationStepIndex = this.registrationSteps.length - 1;
        } else if (this.showRegistrationForm) {
          if (this.currentRegistrationStepIndex === 0) {
            this.showInitialRegistrationScreen = true;
            this.showRegistrationForm = false;
          } else {
            this.currentRegistrationStepIndex--;
          }
        } else if (this.showInitialRegistrationScreen) {
          this.showInitialRegistrationScreen = false;
          return this.$router.push({ path: destinationRoute, query: { pass: true } });
        }
      } else if (this.currentOnboardingStepIndex === 0) {
          return this.$router.push({ path: destinationRoute, query: { pass: true } });
      } else {
        return this.currentOnboardingStepIndex--;
      }

      this.$forceUpdate();
    }
  },
};
</script>

<style scoped>
.datepicker-input {
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  border: 1px solid !important;
  color: #495057 !important;
  background-color: #ffffff !important;
  border-color: #ced4da !important;
}
</style>
