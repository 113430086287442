var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app page-login flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"content",attrs:{"md":"12"}},[_c('CRow',[_c('CCol',{staticClass:"form-container d-flex flex-column justify-content-between align-items-center",attrs:{"xs":"12","md":"12"}},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"form-auth d-flex flex-column justify-content-between align-items-start"},[_c('h3',[_vm._v("Connexion")]),_vm._v(" "),_c('CCard',[_c('CForm',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('CCardBody',[_c('CInput',{attrs:{"type":"email","required":"","autocomplete":"email","addInputClasses":{
                          'is-valid': _vm.isEmailValid(),
                          'is-invalid': _vm.isFormSubmitted && !_vm.isEmailValid(),
                        },"label":_vm.$t('common.field.email'),"placeholder":_vm.$t('common.placeholders.email'),"invalid-feedback":_vm.$t(_vm.email ? 'common.labels.enterValidEmail' : 'common.labels.emptyField')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('CInput',{attrs:{"type":"password","required":"","addInputClasses":{
                          'is-valid': _vm.password,
                          'is-invalid': _vm.isFormSubmitted && !_vm.password,
                        },"autocomplete":"current-password","label":_vm.$t('common.field.password'),"placeholder":_vm.$t('common.placeholders.password'),"invalid-feedback":_vm.$t('common.labels.emptyField')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"btn-wrapper"},[_c('CButton',{staticClass:"btn-confirm",on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.login')))]),_vm._v(" "),_c('CButton',{staticClass:"h-auto mt-3",attrs:{"color":"link"},on:{"click":() => _vm.goToRoute('/forgot-password')}},[_vm._v(_vm._s(_vm.$t('common.buttons.forgot-pwd')))])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }