<template>
  <CWrapper>
    <div v-if="programDayMedia" class="c-body program-day-page pt-0 collapsed">
      <!-- Content -->
      <div class="content bg-white" v-if="programDayMedia.Media">
        <div class="media-header">
          <div class="btn-back" @click.prevent="$router.push('/app/programs/' + programDay.programId)">
            <img :src="arrowLeftIcon" class="arrow-icon" />
          </div>
          <div class="media-extra-info">
            <div v-if="programDayMedia.Media.Tag && programDayMedia.Media.Tag.title" class="card-tag" :style="{ background: getCardColorByProgramDayMedia(programDayMedia) }">
              {{ programDayMedia.Media.Tag.title }}
            </div>
            <div v-if="programDayMedia.Media.tag" class="separator"></div>
            <img :src="clockIcon" class="clock-icon mr-2 ml-2" />
            <b>{{ programDayMedia.Media.estimatedDuration ? convertDurationToHoursAndMinutes(programDayMedia.Media.estimatedDuration) : '0 min' }}</b>
            <div v-if="isProgramDayMediaCompleted(programDayMedia)" class="separator"></div>
            <div
              v-if="isProgramDayMediaCompleted(programDayMedia)"
              class="media-completion-wrapper"
              :style="{ background: getCardColorByProgramDayMedia(programDayMedia) }"
            >
              <img :src="checkIcon" />
            </div>
          </div>
          <img :src="heartIcon" class="heart-icon" />
        </div>
        <div class="media-content-wrapper text-dark">
          <transition name="fade" mode="out-in">
            <h4 class="media-content-title mb-4">
              {{ programDayMedia.Media.title }}
            </h4>
          </transition>
          <transition name="fade" mode="out-in" v-if="isQuizItem() && programDayMedia.Media.Quiz.description">
            <div class="mb-4">
              {{ programDayMedia.Media.Quiz.description }}
            </div>
          </transition>
          <transition name="img-fade" mode="out-in">
            <img
              :key="programDayMedia.id"
              v-if="programDayMedia.Media.image"
              :src="programDayMedia.Media.image"
              class="float-left mb-4 mr-4"
              alt=""
            />
          </transition>
          <transition name="fade" mode="out-in" v-if="isMediaItem()">
            <div class="text-dark" v-html="programDayMedia.Media.content"></div>
          </transition>
          <transition name="fade" mode="out-in" v-if="isQuizItem()">
            <QuizPage :is-embedded="true" :quizId="programDayMedia.Media.Quiz.id" @quiz-completed="onQuizCompleted"></QuizPage>
          </transition>
          <div v-if="showQuizResult">
            <h5 class="quiz-status-label mb-4">Quiz terminé !</h5>
            <div class="quiz-results">
              Bravo d’avoir terminé ce quiz 👏🏻 ! Voici vos résultats ⬇️<br />
              <div class="mt-4" v-html="quizGrading"></div>
              <br />
            </div>
          </div>
        </div>
        <div v-if="!isQuizItem() || isQuizCompleted" class="content-footer d-flex justify-content-center align-items-center px-3">
          <button class="btn btn-info btn-fill mx-2 px-md-4 font-weight-bold order-md-3" @click="showConfirmModal = true">Valider la lecture</button>
        </div>
      </div>
      <!-- End Content-->
    </div>
    <CModal
      :show.sync="quizResultModal"
      :centered="true"
      size="sm"
      :backdrop="true"
      :closeOnBackdrop="false"
      @update:show="(status) => (quizScore = !status ? 0 : quizScore)"
    >
      <template #header>
        <h5 class="modal-title mb-3">Quiz terminé !</h5>
        <CButton @click="quizResultModal = false" class="close text-primary mt-2 mr-2"> x </CButton>
      </template>
      <template #body-wrapper>
        <div class="text-center">
          Bravo d’avoir terminé ce quiz 👏🏻 ! Voici vos résultats ⬇️<br />
          <span v-html="quizGrading"></span><br />
        </div>
      </template>
      <template #footer-wrapper><div></div></template>
    </CModal>
    <CModal
      class="modal-media-confirm"
      :show="showConfirmModal"
      :centered="true"
      size="sm"
      :backdrop="true"
      :closeOnBackdrop="true"
      @update:show="showConfirmModal = false"
    >
      <template #header>
        <div class="modal-header-content">
          <div class="modal-image-wrapper">
            <img :src="medicalRecordImage" />
          </div>
          <h5 class="modal-title">Êtes-vous sur de vouloir valider la lecture de cet article ?</h5>
        </div>
      </template>
      <template #footer-wrapper>
        <div class="modal-button-wrapper">
          <button class="btn btn-info btn-fill" @click="finishProgramDayMedia()">Valider la lecture</button>
          <CButton @click="showConfirmModal = false" class="btn-cancel"> Annuler </CButton>
        </div>
      </template>
    </CModal>
  </CWrapper>
</template>

<script>
import QuizPage from '@/pages/Programs/QuizPage.vue';
import utils from '../../utils';
import medicalRecordImage from '../../assets/images/default-program-image.png';
import arrowLeftIcon from '../../assets/icons/arrow-left.svg';
import heartIcon from '../../assets/icons/heart.svg';
import clockIcon from '../../assets/icons/Clock.svg';
import checkIcon from '../../assets/icons/check.svg';

export default {
  name: 'ProgramDayMedia',
  components: {
    QuizPage,
  },
  async mounted() {
    await this.getProgramDayMedia();
    await this.getPrograms();
  },
  computed: {
    showMediaCompleteButton() {
      if (!this.isQuizItem) {
        return true;
      }

      return this.isQuizCompleted;
    },
  },
  data() {
    return {
      progress: 0,
      programDay: null,
      programDayMedia: null,
      program: null,
      isQuizCompleted: false,
      quizScore: 0,
      quizGrading: '',
      quizResultModal: false,
      showQuizResult: false,
      showConfirmModal: false,
      medicalRecordImage,
      arrowLeftIcon,
      heartIcon,
      clockIcon,
      checkIcon,
    };
  },
  methods: {
    convertDurationToHoursAndMinutes: utils.convertDurationToHoursAndMinutes,
    roundFloatValue: utils.roundFloatValue,
    getCardColorByProgramDayMedia: utils.getCardColorByProgramDayMedia,
    isProgramDayMediaCompleted: utils.isProgramDayMediaCompleted,
    goToRoute(newRoute) {
      this.$router.push(newRoute);
    },
    getPrograms() {
      return this.$store.dispatch('getPrograms');
    },
    async getProgramDayMedia() {
      const { data } = await this.$http.get(`/api/program_day_media/${this.$route.params.id}`);
      if (data && data.body) {
        this.programDayMedia = data.body;

        this.getProgramDay();
      }
    },

    async getProgramDay() {
      if (!this.programDayMedia) {
        return;
      }

      const { data } = await this.$http.get(`/api/program_day/${this.programDayMedia.programDayId}`);
      if (data && data.body) {
        this.programDay = data.body;
      }
    },

    resetContentAreaScroll() {
      window.scrollTo(0, 0);
    },

    onQuizCompleted({ result }) {
      this.isQuizCompleted = true;

      if (!result || !result[0]) {
        return;
      }

      if (this.isQuizItem()) {
        this.programDayMedia.Media.Quiz.UserResults = [result[0]];
      }

      this.quizScore = result[0].score;
      const rating = result && result[0] && result[0].rating;
      if (rating) {
        this.quizGrading = rating;
      }
      // this.quizResultModal = true;
      this.showQuizResult = true;
    },

    async finishProgramDayMedia() {
      await this.$http.put(`/api/program_day_media/${this.programDayMedia.id}/complete`, { status: 'done' });

      if (this.programDay) {
        this.$router.push(`/app/programs?programId=${this.programDay.programId}`);
      }
    },

    isMediaItem() {
      return this.programDayMedia && this.programDayMedia.Media && !this.programDayMedia.Media.Quiz;
    },

    isQuizItem() {
      return this.programDayMedia && this.programDayMedia.Media && this.programDayMedia.Media.Quiz;
    },
  },
};
</script>
